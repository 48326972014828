textarea.form-control:focus {
  border-color: $blue;
  color: $blue;
}

input.form-control:focus {
  border-color: $blue;
  color: $blue;
}

textarea.custom-input:focus {
  border-color: $blue;
  color: $blue;
}

input.custom-input:focus {
  border-color: $blue;
  color: $blue;
}

.text-title {
  width: 9.5rem;
}

.none-event {
  pointer-events: none;
}

.custom-h4 {
  line-height: 1.5;
  color: rgb(52, 58, 64);
  font-size: 1rem;
  margin: 0px;
}

.custom-h3 {
  line-height: 1.5;
  color: rgb(52, 58, 64);
  font-size: 1.125rem;
  margin: 0px;
}

.block-for-text {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  flex: 1 1 0%;
}

.contents {
  font-size: 1rem;
  color: rgb(73, 80, 87);
  line-height: 1.5;
  flex: 1 1 0%;
}

.text-wrapper {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  @media (max-width: 768px) {
    & > div {
      flex-direction: column;
    }

    & > div > div {
      margin-bottom: 0.75rem;
    }
  }
}

.text-wrapper + .text-wrapper {
  border-top: 1px solid rgb(233, 236, 239);
}

.custom-input {
  display: block;
  color: rgb(73, 80, 87);
  font-size: 1rem;
  line-height: 1rem;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(222, 226, 230);
  border-image: initial;
  background: white;
  padding: 0.5rem;
  outline: none;
  border-radius: 4px;
  margin-right: 1rem;
  flex: 1 1 0%;
  width: 350px;
  @media (max-width: 768px) {
    width: 100%;
  }
}

.description {
  margin-top: 0.875rem;
  color: rgb(134, 142, 150);
  font-size: 0.875rem;
}

.thumbnail-area {
  display: flex;
  flex-direction: column;
  padding-right: 1.5rem;
  padding-bottom: 1.5rem;
  img {
    width: 8rem;
    height: 8rem;
    border-radius: 50%;
    display: block;
    margin-bottom: 1.25rem;
  }

  @media (max-width: 768px) {
    img {
      width: 6rem;
      height: 6rem;
      margin-bottom: 1rem;
    }
    button {
      width: 8rem;
    }
    align-items: center;
    padding-right: 0;
  }
}

.img-wrapper {
  width: 350px;
  height: 350px;
  & > img {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }
}

.quill-editors {
  height: 800px !important;
}

.html-renderer {
  width: 100%;
  height: 800px;
  padding: 15px;
  overflow: scroll;
  border: 1px solid rgb(200, 207, 214);
}

.nanobar {
  position: relative;
  .bar {
    position: absolute;
    top: 0;
    background: #71b6f9;
  }
}

.default-button {
  width: 100%;
  padding: 0;
  background: transparent;
  white-space: nowrap;
  border: 0;
}

.input-container {
  & > input + input {
    margin-top: 0.75rem;
  }
}

.cursor {
  cursor: pointer;
}

.ellipsis {
  white-space: nowrap;
  width: 100%;
  // display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
}

.table-base {
  tbody {
    tr {
      td {
        pointer-events: none;
        vertical-align: middle;
      }
    }
  }
}

.red-hover {
  &:hover {
    color: darken($danger, 20%) !important;
    border-color: darken($danger, 20%) !important;
  }
}

.opacity-0 {
  opacity: 0;
}

.opacity-1 {
  opacity: 1;
}

.header-sticky {
  background: #f0f1f3;
  position: sticky;
  top: 0;
  z-index: 10;
  top: -2px;
}

.home-modal-table .scrolltbody {
  display: block;
  height: 370px;
  overflow-y: auto;
  position: sticky;
}

.home-modal-table .scrolltbody th {
  border: 1px solid#dee2e6;
}

.border-collapse {
  box-shadow: 
  0.1px 0 0 0 #dee2e6, 
  0 0.1px 0 0 #dee2e6, 
  0.1px 0.1px 0 0 #dee2e6,
  0.1px 0 0 0 #dee2e6 inset, 
  0 0.1px 0 0 #dee2e6 inset;
}

.hr-divider {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

// .home-modal-table {
// & > {
// .scrolltbody {
//   display: block;
//   width: 58;
//   border-collapse: collapse;
// }
// .scrolltbody th {
//   border: 1px solid#dee2e6;
//   width: auto;
//   z-index: 1;
// }
// .scrolltbody td {
//   border: 1px solid #dee2e6;
//   border-top: 0;
// }
// .scrolltbody tbody {
//   display: block;
//   height: 370px;
//   overflow-y: auto;
//   position: sticky;
// }

// .scrolltbody th:nth-of-type(1) {
//   width: 57px;
// }

// .scrolltbody td:nth-of-type(1) {
//   width: 57px;
// }

// .scrolltbody th:nth-of-type(2) {
//   width: 323px;
// }

// .scrolltbody td:nth-of-type(2) {
//   width: 323px;
// }

// .scrolltbody th:nth-of-type(3) {
//   width: 349px;
// }

// .scrolltbody td:nth-of-type(3) {
//   width: 349px;
// }

// .scrolltbody th:nth-of-type(4) {
//   width: 203px;
// }

// .scrolltbody td:nth-of-type(4) {
//   width: 203px;
// }

// .scrolltbody th:nth-of-type(5) {
//   width: 204px;
// }

// .scrolltbody td:nth-of-type(5) {
//   width: 204px;
// }

// .scrolltbody th:nth-of-type(6) {
//   width: 204px;
// }

// .scrolltbody td:nth-of-type(6) {
//   width: 204px;
// }

// .scrolltbody th:nth-of-type(7) {
//   width: 172px;
// }

// .scrolltbody td:nth-of-type(7) {
//   width: 172px;
// }

// .scrolltbody th:last-child {
//   // width: calc(338px - 33px);
//   width: 338px;
// }
// .scrolltbody td:last-child {
//   // width: calc(338px - 33px);
//   width: 338px;
// }
// }
// }
